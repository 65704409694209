
// css transition for tabs
.vue-tabs .tab-content {
  padding-top: 10px;
  min-height: 100px;
  display: flex; // to avoid horizontal scroll when animating
  .tab-container {
    animation: fadeIn 0.5s;
  }
}
